<template>
  <div class="dashboard container">
    <div class="topHead hide-on-lg">
      <navigation />
      <!-- <base-select :options="options" v-model="state" initialValue="Active" /> -->
    </div>
    <div class="row">
      <div class="col-lg-4">
        <profile-card />
        <base-statictics />
      </div>
      <div class="col-lg-8">
        <!-- Filter Start -->
        <div>
          <ul class="statesLinks">
            <li class="statesLinks__item">
              <a
                class="statesLinks__item--link"
                :class="this.state == 'active' ? 'active' : ''"
                href="javascript:void(0)"
                @click="handleTab('active')"
                >{{$t('Active')}} ({{ activeCampaignsCount }})</a
              >
            </li>
            <li class="statesLinks__item">
              <a
                class="statesLinks__item--link"
                :class="this.state == 'inactive' ? 'active' : ''"
                href="javascript:void(0)"
                @click="handleTab('inactive')"
                >{{$t('Inactive')}} ({{ inactiveCampaignsCount }})</a
              >
            </li>
            <li class="statesLinks__item">
              <a
                class="statesLinks__item--link"
                :class="this.state == 'pending' ? 'active' : ''"
                href="javascript:void(0)"
                @click="handleTab('pending')"
                >{{$t('Pending')}} ({{ pendingCampaignsCount }})</a
              >
            </li>
          </ul>
          <div v-if="$apollo.loading">
            <base-skeleton-loader
              type="discover"
              :count="4"
            ></base-skeleton-loader>
          </div>
          <div v-if="norecord">No active/in-active/pending campaign found</div>
          <div v-if="!$apollo.loading && !norecord">
          <campaign-card
            v-for="campaign in myCampaigns"
            :key="campaign.id"
            :campaign="campaign"
            :islink="true"
          />
        </div>
        </div>
        <!-- Filter End -->
        <!-- <base-select
          class="onMobile"
          :options="options"
          initialValue="Active"
          v-model="state"
        />
        <div v-if="$apollo.loading">
          <base-skeleton-loader
            type="discover"
            :count="4"
          ></base-skeleton-loader>
        </div>
        <div v-if="norecord && !$apollo.loading">No Record Found</div>
        <div v-if="!$apollo.loading && !norecord">
          <campaign-card
            v-for="campaign in myCampaigns"
            :key="campaign.id"
            :campaign="campaign"
            :islink="true"
          />
        </div> -->
      </div>
    </div>
    <!-- Tour Pop Ups -->
    <tour />
  </div>
</template>

<script>
import { My_CAMPAIGNS } from "@/graphql/brand/query";
import { CAMPAIGN_SUBSCRIPTION } from "@/graphql/user/query";
export default {
  components: {
    ProfileCard: () =>
      import(
        /* webpackChunkName: "profileCard" */ "@/components/common/ProfileCard.vue"
      ),
    CampaignCard: () =>
      import(
        /* webpackChunkName: "campaignCard" */ "@/components/brand/campaigns/CampaignCard.vue"
      ),
    navigation: () =>
      import(
        /* webpackChunkName: "navigations" */ "@/components/brand/Navigation.vue"
      ),
    Tour: () =>
      import(/* webpackChunkName: "tour" */ "@/components/brand/Tour.vue"),
  },
  data() {
    return {
      page: 1,
      limit: 20,
      state: "active",
      norecord: false,
      options: [
        { value: "active", text: "Active" },
        { value: "inactive", text: "In-active" },
        { value: "pending", text: "Pending" },
      ],
      //himanshu rehani
      activeCampaignsCount: 0,
      inactiveCampaignsCount: 0,
      pendingCampaignsCount: 0,
    };
  },
  apollo: {
    myCampaigns: {
      query: My_CAMPAIGNS,
      variables() {
        return {
          page: this.page,
          limit: this.limit,
          state: this.state,
        };
      },
      result(data) {
        if (data.data.myCampaigns.length == 0) {
          this.norecord = true;
        } else {
          this.norecord = false;
        }
      },
    },
  },
  created() {
    this.getCampaignsCount();
  },
  methods: {
    handleTab(data) {
      this.norecord = false;
      this.state = data;
    },
    getSubscriptionTitle() {
      return this.state.charAt(0).toUpperCase() + this.state.slice(1);
    },
    async getCampaignsCount() {
      await this.$apollo
        .query({
          query: My_CAMPAIGNS,
          variables: {
            page: this.page,
            limit: this.limit,
          }
        })
        .then((data) => {
          const campaigns = data.data.myCampaigns;
          let activeCampaigns = 0;
          let inactiveCampaigns = 0;
          let pendingCampaigns = 0;
          if(campaigns.length) {
            for(let i = 0; i < campaigns.length; i++) {
              if(campaigns[i].state == 'active') {
                activeCampaigns++;
              } else if(campaigns[i].state == 'inactive') {
                inactiveCampaigns++;
              } else if(campaigns[i].state == 'pending') {
                pendingCampaigns++;
              }
            }
            this.activeCampaignsCount = activeCampaigns;
            this.inactiveCampaignsCount = inactiveCampaigns;
            this.pendingCampaignsCount = pendingCampaigns;
          }
        })
        .catch((e) => {
          this.handleError(e);
        });
    },
  }
};
</script>

<style lang="scss" scoped>
.dashboard {
  .topHead {
    margin-bottom: rem(20px);
  }
  .onMobile {
    display: none;
    @media screen and (max-width: 767px) {
      display: block !important;
    }
  }
  ::v-deep {
    .v-select-toggle {
      color: var(--textPrimary) !important;
      font-weight: 600 !important;
      height: 40px !important;
      min-width: 170px;
      padding-left: rem(16px);
      padding-right: rem(50px);
      @media screen and (max-width: 767px) {
        font-size: rem(18px);
      }
      .arrow-down {
        width: 16px;
        height: 8px;
        transform: translateY(-50%);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.828' height='8.414' viewBox='0 0 14.828 8.414'%3E%3Cg id='dropdown' transform='translate(0.414 0.414)'%3E%3Cpath id='Path_180' data-name='Path 180' d='M1,1,7,7l6-6' fill='none' stroke='%23c9ced6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E");
      }
    }
    .statistics__earning {
      display: block !important;
    }
  }

  .statesLinks {
    list-style: none;
    display: flex;
    align-items: center;
    margin-bottom: rem(18px);
    @media screen and (max-width: 575px) {
      margin-top: rem(18px);
    }
    &__item {
      background: #fff;
      margin-right: rem(13px);
      border-radius: 8px;
      overflow: hidden;
      @media screen and (max-width: 575px) {
        width: 23%;
      }
      &--link {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 40px;
        min-width: 120px;
        padding: rem(9px);
        font-weight: 700;
        font-size: rem(14px);
        text-align: center;
        background: #fff;
        color: var(--textPrimary);
        &.active {
          background: var(--primary);
          color: #fff;
        }
        @media screen and (max-width: 575px) {
          min-width: auto;
        }
      }
      &:hover {
        @media screen and (min-width: 1025px) {
          a {
            opacity: 0.8;
          }
        }
      }
    }
  }
}
</style>
