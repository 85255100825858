<template>
  <div class="dashboard container">
    <div class="row">
      <div class="col-lg-4">
        <profile-card />
        <base-statictics />
      </div>
      <div class="col-lg-8">
        <div>
          <ul class="statesLinks">
            <li class="statesLinks__item">
              <a
                class="statesLinks__item--link"
                :class="this.subscription == 'active' ? 'active' : ''"
                href="javascript:void(0)"
                @click="handleTab('active')"
                >{{ $t("dashboard.active") }} ({{ activeCampaignsCount }})</a
              >
            </li>
            <li class="statesLinks__item">
              <a
                class="statesLinks__item--link"
                :class="this.subscription == 'revision' ? 'active' : ''"
                href="javascript:void(0)"
                @click="handleTab('revision')"
                >{{ $t("dashboard.revision") }} ({{
                  revisionCampaignsCount
                }})</a
              >
            </li>
            <li class="statesLinks__item">
              <a
                class="statesLinks__item--link"
                :class="this.subscription == 'completed' ? 'active' : ''"
                href="javascript:void(0)"
                @click="handleTab('completed')"
                >{{$t('dashboard.completed')}} ({{ completedCampaignsCount }})</a
              >
            </li>
            <li class="statesLinks__item">
              <a
                class="statesLinks__item--link"
                :class="this.subscription == 'pending' ? 'active' : ''"
                href="javascript:void(0)"
                @click="handleTab('pending')"
                >{{$t('dashboard.pending')}} ({{ pendingCampaignsCount }})</a
              >
            </li>
          </ul>
          <div v-if="$apollo.loading">
            <base-skeleton-loader
              type="discover"
              :count="4"
            ></base-skeleton-loader>
          </div>
          <div v-if="norecord">{{$t('No')}} {{ getSubscriptionTitle() }} {{$t('Campaign Found')}}</div>
          <div class="campaigns" v-if="!$apollo.loading && !norecord">
            <compaign-card
              v-for="campaign in campaigns"
              :key="campaign.id"
              :campaign="campaign"
              :isApply="false"
              :islink="true"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Tour Pop Ups -->
    <tour />
  </div>
</template>

<script>
import { CAMPAIGN_SUBSCRIPTION } from "@/graphql/user/query";

export default {
  data() {
    return {
      campaigns: [],
      page: 1,
      subscription: "active",
      norecord: false,
      complete: 61,
      response: 68,
      onTime: 95,
      activeCampaignsCount: 0,
      revisionCampaignsCount: 0,
      completedCampaignsCount: 0,
      pendingCampaignsCount: 0,
    };
  },
  components: {
    ProfileCard: () =>
      import(
        /* webpackChunkName: "profileCard" */ "@/components/common/ProfileCard.vue"
      ),
    CompaignCard: () =>
      import(
        /* webpackChunkName: "compaignCard" */ "@/components/user/CompaignCard.vue"
      ),
    Tour: () =>
      import(
        /* webpackChunkName: "compaignCard" */ "@/components/user/Tour.vue"
      ),
  },
  apollo: {
    campaigns: {
      query: CAMPAIGN_SUBSCRIPTION,
      variables() {
        return {
          page: null,
          locations: null,
          categories: null,
          search: null,
          subscriptions: this.subscription,
          countCampaigns: false,
        };
      },
      result(data) {
        this.norecord = false;
        if (data.data) {
          if (data.data.campaigns.length == 0) {
            this.norecord = true;
          } else {
          }
        }
      },
    },
  },
  created() {
    this.getCampaignsCount();
  },
  methods: {
    handleTab(data) {
      this.norecord = false;
      this.subscription = data;
    },
    getSubscriptionTitle() {
      return this.subscription.charAt(0).toUpperCase() + this.subscription.slice(1);
    },
    getCampaignsCount() {
      this.$apollo
        .query({
          query: CAMPAIGN_SUBSCRIPTION,
          variables: {
            page: null,
            locations: null,
            categories: null,
            search: null,
            countCampaigns: true,
          }
        })
        .then((data) => {
          const campaigns = data.data.campaigns;
          let activeCampaigns = 0;
          let revisionCampaigns = 0;
          let pendingCampaigns = 0;
          let completedCampaigns = 0;
          if(campaigns.length) {
            for(let i = 0; i < campaigns.length; i++) {
              if(campaigns[i].subscription.state == 'active') {
                activeCampaigns++;
              } else if(campaigns[i].subscription.state == 'revision') {
                revisionCampaigns++;
              } else if(campaigns[i].subscription.state == 'pending') {
                pendingCampaigns++;
              } else if(campaigns[i].subscription.state == 'completed') {
                completedCampaigns++;
              }
            }
            this.activeCampaignsCount = activeCampaigns;
            this.revisionCampaignsCount = revisionCampaigns;
            this.pendingCampaignsCount = pendingCampaigns;
            this.completedCampaignsCount = completedCampaigns;
          }
        })
        .catch((e) => {
          this.handleError(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  .statesLinks {
    list-style: none;
    display: flex;
    align-items: center;
    margin-bottom: rem(18px);
    @media screen and (max-width: 575px) {
      margin-top: rem(18px);
    }
    &__item {
      background: #fff;
      margin-right: rem(13px);
      border-radius: 8px;
      overflow: hidden;
      @media screen and (max-width: 575px) {
        width: 23%;
      }
      &--link {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 40px;
        min-width: 120px;
        padding: rem(9px);
        font-weight: 700;
        font-size: rem(14px);
        text-align: center;
        background: #fff;
        color: var(--textPrimary);
        &.active {
          background: var(--primary);
          color: #fff;
        }
        @media screen and (max-width: 575px) {
          min-width: auto;
        }
      }
      &:hover {
        @media screen and (min-width: 1025px) {
          a {
            opacity: 0.8;
          }
        }
      }
    }
  }
}
</style>
